import React from "react";
import PropTypes from "prop-types";
import "./style.css";

const CoverImage = ({ children, url }) => (
  <div className="fpl__cover-image" style={{ backgroundImage: `url(${url})` }}>
    {children}
  </div>
);

CoverImage.propTypes = {
  children: PropTypes.node,
  url: PropTypes.string.isRequired,
};

CoverImage.defaultProps = {
  children: "",
};

export default CoverImage;
