/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { RichText } from "prismic-reactjs";
import Card from "../components/card";
import CardGroup from "../components/cardGroup";
import CardImage from "../components/cardImage";
import CardActionArea from "../components/cardActionArea";
import CoverImage from "../components/coverImage";
import HeadingRow from "../components/headingRow";
import LightBox from "../components/lightBox";
import Logo from "../components/logo";
import Layout from "../components/layout";
import SafeZone from "../components/safeZone";
import ParallaxImage from "../components/parallaxImage";
import TwoColumn from "../components/twoColumn";
import SEO from "../components/seo";
import Zebra from "../components/zebra";

const IndexPage = ({
  data: {
    allPrismicHomepage: {
      edges: [
        {
          node: {
            data: { service, key_info_cards: cards, testimonials },
          },
        },
      ],
    },
  },
}) => (
  <Layout>
    <LightBox>
      <HeadingRow>
        <Logo style={{ width: "70vw" }} mode="light" highlight="black" />
      </HeadingRow>
      <HeadingRow>
        <h2 style={{ color: "var(--color-black)" }}>
          The start-up to scale-up Consultancy
        </h2>
      </HeadingRow>
    </LightBox>
    <SEO title="Home" />
    <Zebra>
      {service.map(
        ({
          main_image: { url },
          heading: {
            raw: [heading],
          },
          text: {
            raw: [text],
          },
        }) => (
          <TwoColumn key={heading.text}>
            <CoverImage url={url} />
            <SafeZone width="relative">
              <h2>{heading.text}</h2>
              <p>{text.text}</p>
            </SafeZone>
          </TwoColumn>
        ),
      )}
    </Zebra>
    <ParallaxImage
      height="bar"
      url="https://images.unsplash.com/photo-1514285490982-4130e9aefedb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=934&q=80"
    >
      <CardGroup maxColumns={3}>
        {testimonials.map(
          ({
            image: { url },
            name: { raw: rawName },
            quote: { raw: rawQuote },
            title,
            rating,
          }) => (
            <Card key={title}>
              <CardActionArea>
                <img src={url} alt="vu.city" />
                {rawQuote.map((e) => (
                  <p key={e.text + e.type}>{RichText.asText([e])}</p>
                ))}
                {rawName.map((e) => (
                  <h3 key={e.text + e.type}>{RichText.asText([e])}</h3>
                ))}
                <h4>{title}</h4>

                {[...new Array(rating)].map((_e, i) => (
                  <span key={`star-${i}`} role="img" aria-label="star">
                    ⭐️
                  </span>
                ))}
              </CardActionArea>
            </Card>
          ),
        )}
      </CardGroup>
    </ParallaxImage>
    <ParallaxImage
      height="bar"
      url="https://images.unsplash.com/photo-1527117499127-8169c886e66e?ixlib=rb-1.2.1&auto=format&fit=crop&w=668&q=80"
    >
      <CardGroup maxColumns={2}>
        {cards.map(
          ({
            card_title: {
              raw: [{ text: title }],
            },
            card_body: { raw },
            card_image: { url },
          }) => (
            <Card key={title}>
              <CardImage url={url} />
              <CardActionArea>
                <h3>{title}</h3>
                {raw.map((e) => (
                  <p key={e.text + e.type}>{RichText.asText([e])}</p>
                ))}
              </CardActionArea>
            </Card>
          ),
        )}
      </CardGroup>
    </ParallaxImage>

    <ParallaxImage
      height="halfScreen"
      url="https://images.unsplash.com/photo-1517086365419-f247ad2f3871?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=913&q=80"
    />
  </Layout>
);
export default IndexPage;

IndexPage.propTypes = {
  /* Populated by gatsby during graphql request */
  data: PropTypes.object,
};

IndexPage.defaultProps = {
  data: null,
};

export const query = graphql`
  query {
    allPrismicHomepage {
      edges {
        node {
          id
          data {
            body {
              raw {
                type
                text
              }
            }
            key_info_cards {
              card_body {
                raw {
                  text
                  type
                }
              }
              card_image {
                alt
                url
              }
              card_title {
                raw {
                  text
                  type
                }
              }
            }
            service {
              heading {
                raw {
                  text
                }
              }
              main_image {
                url
              }
              text {
                raw {
                  text
                }
              }
            }
            testimonials {
              image {
                url
              }
              name {
                raw {
                  text
                  type
                }
              }
              quote {
                raw {
                  text
                  type
                  spans {
                    type
                    start
                    end
                  }
                }
              }
              title
              rating
            }
          }
        }
      }
    }
  }
`;
