import React from "react";
import ClassNames from "classnames";
import PropTypes from "prop-types";

import "./style.css";

const TwoColumn = ({ children: [left, right], direction, variant }) => (
  <section
    className={ClassNames([
      "fpl__two-column",
      variant === "fullScreen" && "fpl__two-column--fullscreen",
      direction === "row" ? "fpl__two-column--row" : "fpl__two-column--column",
    ])}
  >
    <div>{left}</div>
    <div>{right}</div>
  </section>
);

TwoColumn.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  direction: PropTypes.oneOf(["row", "column"]),
  variant: PropTypes.oneOf(["fullScreen", "nested"]),
};

TwoColumn.defaultProps = {
  direction: "row",
  variant: "fullScreen",
};

export default TwoColumn;
