import React from "react";
import PropTypes from "prop-types";
import "./style.css";
import ChevronPair from "../../images/chevron-pair.svg";
import useDimensions from "../../utils/hooks/useDimensions";

const LightBox = ({ children }) => {
  const [ref, { height }] = useDimensions();
  const doAScroll = () =>
    window.scrollBy({ top: height * 0.75, behavior: "smooth" });
  return (
    <div className="fpl__light-box" ref={ref}>
      <br />
      {children}
      <button
        onClick={doAScroll}
        type="button"
        className="fpl__light-box--button"
      >
        <img
          className="fpl__light-box--arrow"
          src={ChevronPair}
          alt="scroll down"
        />
      </button>
    </div>
  );
};
LightBox.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LightBox;
